import React, { useState, useRef, useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { Drawer } from '@material-ui/core';
import moment from 'moment';
import QrCodeModal from '../../../components/QrCodeModal';
import NiceModal from '@ebay/nice-modal-react';

import { useSelector } from 'react-redux';
import Filters from './UI/Filters';
import Table from './UI/Table';
import ContentFilterDrawer from './UI/ContentFilterDrawer';
import TablePaginationActions from './UI/TablePaginationActions';
import BankDataModal from './UI/BankDataModal';
import Header from './UI/Header';
import ChipsFilter from './UI/ChipsFilter';

import LogoLoading from '../../../components/LogoLoading';
import ResultNotFound from '../../../components/ResultNotFound';
import MobileList from '../../../components/MobileList';
import FloatButton from '../../../components/FloatButton';

import { useValidationForUnformattedDate } from '../../../hooks/useValidationForUnformattedDate';

import style from './styles.module.css';
import { styles, useStyles } from './styles';
import { actions } from './actions';

import { api } from '../../../services/api';

import CashInServices from './utils/services';
import { isMobile } from '../../../utils/breakpoints';
import convertBytesToBlobDowload from '../../../utils/convertBytesToBlobDowload';
import formatCnpj from '../../../utils/formatCnpj';
import { getCurrentCompanieData } from '../../../utils/getCurrentCompanieData';
import { useCreateRegenQrCodePixMutation } from '../../../redux/store/CashIn';

import { ReactComponent as DollarSign } from '../../../assets/DollarSign.svg';
import { handleSnackbarStack } from '../../../utils/handleSnackbarStack';
import { getAccountSCD } from '../../../utils/getAccountSCD';

const ConsultCashIn = () => {
  const { error } = handleSnackbarStack()
  const classes = useStyles();
  const history = useHistory();
  const { url } = useRouteMatch();
  const anchorRef = useRef(null);
    
  const {
    currentCompany:{cnpj},
    companies
  } = useSelector(state => state.companies);

  const currentCompanyData = getCurrentCompanieData(cnpj, companies)

  const [ createRegenQrCodePix, { isLoading:isLoadingQrCode } ] = useCreateRegenQrCodePixMutation()

  const [anchorEl, setAnchorEl] = useState(null);

  const currentCompanyCode = sessionStorage.getItem('currentCompanyCode');

  const { accountSelected, accounts } = useSelector(state => state.account);
  const { currentCompany, companies: companiesData } = useSelector(state => state.companies);
  const currentCompanyAllData =companiesData.find(company => company.cnpj === currentCompany.cnpj);
  const accountSCD = getAccountSCD(accounts, 'SCD')

  const [
    awaitingConfirmationAnchorEl,
    setAwaitingConfirmationAnchorEl,
  ] = useState(null);
  const [otherOptionsAnchorEl, setOtherOptionsAnchorEl] = useState(null);

  const [loading, setLoading] = useState(false);
  const [loadingLogo, setLoadingLogo] = useState(true);

  const [totalSize, setTotalSize] = useState(0);
  const [currentPageSize, setCurrentPageSize] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [cashInSelected, setCashInSelected] = useState();
  const [cashInValues, setCashInValues] = useState([]);
  const [cashinData, setCashinData] = useState([]);

  const [depositType, setDepositType] = useState('Todos');
  const [depositSituation, setDepositSituation] = useState('Todos');
  const [initialDate, setInitialDate] = useState('');
  const [finalDate, setFinalDate] = useState('');
  const [changedFilter, setChangedFilter] = useState(false);
  const [cleanFilter, setCleanFilter] = useState(false);

  const [datesInput, setDatesInput] = useState(false);
  const [loadingList, setLoadingList] = useState(false);
  const [isFilteredType, setIsFilteredType] = useState(false);
  const [isFilteredSituation, setIsFilteredSituation] = useState(false);
  const [isFilteredFinalDate, setIsFilteredFinalDate] = useState(false);
  const [fetchData, setFetchData] = useState(false);

  const [type, setType] = useState('');
  const [situation, setSituation] = useState('');
  const [date1, setDate1] = useState('');
  const [date2, setDate2] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);

  const validateInitialDateInfo = useValidationForUnformattedDate(initialDate);
  const validateFinalDateInfo = useValidationForUnformattedDate(finalDate);

  const FILTER_FULL_LIST = 'Todos';

  useEffect(() => {
    if (!depositType && !depositSituation && !finalDate) getDeposits();
  }, [cleanFilter]);

  useEffect(() => {
    if (
      initialDate ||
      finalDate ||
      depositSituation !== 'Todos' ||
      depositSituation === 0 ||
      depositType !== 'Todos'
    ) {
      setCleanFilter(false);
      setChangedFilter(true);
    }
  }, [initialDate, finalDate, depositSituation, depositType]);

  useEffect(() => {
    if (!initialDate && !finalDate) {
      setDatesInput(false);
      setCleanFilter(false);
    }

    if (
      (initialDate &&
        finalDate &&
        !validateFinalDateInfo &&
        !validateInitialDateInfo &&
        !isMobile) ||
      cleanFilter
    ) {
      setCurrentPage(0);
      setDatesInput(true);
      getDeposits();
    }

    if (
      ((!initialDate && !finalDate) ||
        (!initialDate && finalDate && !validateFinalDateInfo) ||
        (initialDate && !finalDate && !validateInitialDateInfo)) &&
      datesInput &&
      !isMobile
    ) {
      getDeposits();
    }
  }, [initialDate, finalDate]);

  useEffect(() => {
    getDeposits();
    sessionStorage.removeItem('editDepositInform');
    sessionStorage.removeItem('billetData');
    sessionStorage.removeItem('billetValue');
  }, []);

  useEffect(() => {
    if (!isMobile) getDeposits();
  }, [
    currentPage,
    pageSize,
    currentCompanyCode,
    depositSituation,
    depositType,
    cleanFilter,
  ]);

  useEffect(() => {
    if (!isFilteredType || !isFilteredSituation || !isFilteredFinalDate) {
      getDeposits();
    }
  }, [isFilteredType, isFilteredSituation, isFilteredFinalDate]);

  const getDeposits = () => {
    setLoading(true);
    setLoadingList(true);

    CashInServices.cashInSearch({
      requestOptions: {
        params: {
          page: initialDate && finalDate ? 0 : currentPage,
          size: pageSize,
          status: depositSituation === FILTER_FULL_LIST ? '' : depositSituation,
          type: depositType === FILTER_FULL_LIST ? '' : depositType,
          initialDate: initialDate && finalDate ? initialDate : '',
          finalDate: initialDate && finalDate ? finalDate : '',
        },
      },
      currentCompanyCode,
      setStates: dataResult => {
        setCashInValues(dataResult.dataResult);
        setCashinData(dataResult.dataResult);
        setTotalSize(dataResult.totalSize);
        setCurrentPageSize(dataResult.currentPageSize);
        setLoading(dataResult.loading);
        setLoadingLogo(false);
        setLoading(false);
        setLoadingList(false);
        setFetchData(false);
      },
    });
  };

  const handleOtherOptionsClick = ({ currentTarget }) => {
    setOtherOptionsAnchorEl(currentTarget);
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setPageSize(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const handleAwaitingConfirmationClick = ({ currentTarget }) => {
    setAwaitingConfirmationAnchorEl(currentTarget);
  };

  const handleAwaitingConfirmationClose = () => {
    setAwaitingConfirmationAnchorEl(null);
  };

  const handleOtherOptionsClose = () => {
    setOtherOptionsAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = ({ currentTarget }) => {
    setAnchorEl(currentTarget);
  };

  const handleDowloadPdf = row => {
    api
      .get(`/cashIn/bankTickets/${currentCompanyCode}/${row?.id}`, {
        responseType: 'blob',
      })
      .then(({ data }) => {
        const nameDocument = 'boleto';
        convertBytesToBlobDowload(data, nameDocument);
      });
  };

  const updateQrCode = (array, id, response) => {
    return array.map(item => {
      if (item.id === id) {
        return {
          ...item,
          id: response.cashEntryId,
          qrCodeBase64: response.base64Qrcode,
          qrCodeCopyAndPaste: response.copyPasteCode,
          qrcodeExpired: false,
          status: 'Aguardando confirmação'
        };
      }
      return item;
    });
  };

  const handleClickQrCode = (row) => {
    if(!row?.qrcodeExpired){
      return (
        NiceModal.show(QrCodeModal, {
          qrCodeBase64: row.qrCodeBase64,
          qrCodeCopyAndPaste: row.qrCodeCopyAndPaste,
          nsu: row?.nsu
        })
      )
    }

    handleRegenQrCode(row)
  };

  const handleRegenQrCode = (row) => {
      setCashInSelected(row)
      createRegenQrCodePix({
        cashinId: row.id, pixProvider: currentCompanyData?.pixProvider
      }).unwrap()
      .then((data) => {
        const newCashInData = updateQrCode(cashInValues,row.id, data)
        setCashInValues(newCashInData);
        setCashinData(newCashInData);
        handleClickQrCode({
          qrCodeBase64: data.base64Qrcode,
          qrCodeCopyAndPaste: data.copyPasteCode,
        })
      }).catch(err=>{
        error('Não foi possível regerar o Pix no momento. Por favor, tente novamente em alguns instantes.')
      })
  };

  const handleChangeType = event => {
    setCurrentPage(0);
    setDepositType(event.target.value);
  };

  const handleChangeSituation = event => {
    setCurrentPage(0);
    setDepositSituation(event.target.value);
  };

  const onChangeInitialDate = value => {
    setInitialDate(
      moment(value).format('YYYY-MM-DD') !== 'Data inválida'
        ? moment(value).format('YYYY-MM-DD')
        : value,
    );
  };

  function onChangeFinalDate(value) {
    setFinalDate(
      moment(value).format('YYYY-MM-DD') !== 'Data inválida'
        ? moment(value).format('YYYY-MM-DD')
        : value,
    );
  }

  function deposityStatusToWord(status) {
    const statusPayment = {
      1: 'Confirmado',
      2: 'Aguardando confirmação',
      3: 'Cancelado',
      4: 'Pago',
      5: 'Vencido',
      6: 'Verificação em processamento',
      7: 'Verificação desfeita',
      8: 'Transferência não confirmada',
      9: 'Transferência confirmada',
      10: 'Aguardando pagamento',
    };

    return statusPayment[status] ?? statusPayment['Aguardando pagamento'];
  }

  function handleClearFilters() {
    setType('');
    setSituation('');
    setDate1('');
    setDate2('');

    setInitialDate('');
    setFinalDate('');
    setDepositType('Todos');
    setDepositSituation('Todos');

    setCleanFilter(true);
  }

  function handleClickDataBank() {
    setOpenModal(true);
  }

  function resetFilter() {
    setIsFilteredSituation(false);
    setIsFilteredType(false);
    handleClearFilters();
    getDeposits();
  }

  function renderDrawer() {
    return (
      <>
        <Drawer
          anchor="right"
          open={openDrawer}
          onClose={() => setOpenDrawer(false)}
          className={!isMobile && styles.hidden}
        >
          <ContentFilterDrawer
            depositType={depositType}
            setDepositType={setDepositType}
            depositSituation={depositSituation}
            setDepositSituation={setDepositSituation}
            initialDate={initialDate}
            setInitialDate={setInitialDate}
            finalDate={finalDate}
            setFinalDate={setFinalDate}
            handleClearFilters={handleClearFilters}
            setIsFilteredType={setIsFilteredType}
            setIsFilteredSituation={setIsFilteredSituation}
            setIsFilteredFinalDate={setIsFilteredFinalDate}
            getDeposits={getDeposits}
            setOpenDrawer={setOpenDrawer}
            type={type}
            setType={setType}
            situation={situation}
            setSituation={setSituation}
            date1={date1}
            date2={date2}
            setDate1={setDate1}
            setDate2={setDate2}
          />
        </Drawer>
      </>
    );
  }

  const renderHeader = () => {
    return (
      <>
        <Header
          handleClickDataBank={handleClickDataBank}
          setOpenDrawer={setOpenDrawer}
          handleClick={handleClick}
          handleClose={handleClose}
          anchorRef={anchorRef}
          anchorEl={anchorEl}
          classes={classes}
          history={history}
          url={url}
          changedFilter={changedFilter}
          cashInValues={cashInValues}
          hasTedCashIn={accountSelected?.origin === 'FACIL_IP'}
          disabled={accountSelected?.status === 'CLOSED'}
        />

        <BankDataModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          title="Dados Bancários"
          socialReason={currentCompanyAllData?.parentCompanyName || currentCompanyAllData?.name}
          bankName={'520 - Somapay SCD'
          }
          agency="0001"
          accountNumber={accountSCD?.id}
          cnpj={formatCnpj(currentCompanyAllData?.parentCompanyCnpj || currentCompanyAllData?.cnpj)}
        />
      </>
    );
  };

  const renderFilters = () => {
    return isMobile ? (
      <ChipsFilter
        depositType={depositType}
        isFilteredType={isFilteredType}
        isFilteredSituation={isFilteredSituation}
        setIsFilteredType={setIsFilteredType}
        setDepositType={setDepositType}
        depositSituation={depositSituation}
        setDepositSituation={setDepositSituation}
        deposityStatusToWord={deposityStatusToWord}
        setIsFilteredSituation={setIsFilteredSituation}
        finalDate={finalDate}
        setFinalDate={setFinalDate}
        isFilteredFinalDate={isFilteredFinalDate}
        initialDate={initialDate}
        setInitialDate={setInitialDate}
        setIsFilteredFinalDate={setIsFilteredFinalDate}
      />
    ) : (
      <Filters
        depositType={depositType}
        depositSituation={depositSituation}
        initialDate={initialDate}
        finalDate={finalDate}
        handleChangeType={handleChangeType}
        handleChangeSituation={handleChangeSituation}
        onChangeInitialDate={onChangeInitialDate}
        onChangeFinalDate={onChangeFinalDate}
        validateInitialDateInfo={validateInitialDateInfo}
        validateFinalDateInfo={validateFinalDateInfo}
      />
    );
  };

  function renderTable() {
    return isMobile ? (
      <MobileList
        data={cashinData}
        loadingList={loadingList}
        pageSize={pageSize}
        setPageSize={setPageSize}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        getData={getDeposits}
        currentPageSize={currentPageSize}
        totalSize={totalSize}
        cashinData={cashinData}
        isMobile={isMobile}
        setFetchData={setFetchData}
        fetchData={fetchData}
        openDrawer={openDrawer}
        handleClickQrCode={handleClickQrCode}
        cashInSelected={cashInSelected}
        isLoadingQrCode={isLoadingQrCode}
      />
    ) : (
      <Table
        data={cashInValues}
        totalSize={totalSize}
        pageSize={pageSize}
        currentPage={currentPage}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        TablePaginationActions={TablePaginationActions}
        handleClickQrCode={handleClickQrCode}
        loading={loading}
        cashInSelected={cashInSelected}
        isLoadingQrCode={isLoadingQrCode}
      />
    );
  }

  if (loadingLogo) {
    return <LogoLoading />;
  }

  if (cashInValues.length === 0) {
    return (
      <>
        {renderHeader()}

        {loading ? (
          <>
            {renderFilters()}
            {renderTable()}
          </>
        ) : changedFilter ? (
          <>
            {renderFilters()}

            <ResultNotFound
              text="Nenhum Resultado Encontrado"
              buttonText="Limpar Filtros"
              onClick={() => resetFilter()}
            />
          </>
        ) : (
          <ResultNotFound
            text="A lista de seus depósitos aparecerá aqui."
            alternativeText="Clique em Novo Depósito para registrar sua primeira"
            icon={<DollarSign />}
          />
        )}

        <FloatButton actions={actions} hidden={!isMobile} />
        {renderDrawer()}
      </>
    );
  }

  return (
    <div className={style.cashinContainer}>
      {renderHeader()}
      {renderFilters()}
      {renderTable()}

      <FloatButton actions={actions} hidden={!isMobile} />

      {renderDrawer()}
    </div>
  );
};

export default ConsultCashIn;
