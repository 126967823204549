import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Lottie from 'react-lottie';
import { useHistory } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { Box, Button, Grid, Typography } from '@material-ui/core';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import AccessTimeRoundedIcon from '@material-ui/icons/AccessTimeRounded';

import NiceModal from '@ebay/nice-modal-react';
import ContentDrawer from './UI/Drawer';
import InfoGrid from './InfoGrid';

import ProgressToolBar from '../../../components/ProgressToolBar';
import LoadingModal from '../../../components/LoadingModal';
import AuthorizePayrollModal from '../../../components/ProgressModal';
import InsufficientBalanceModal from '../../../components/BalancesModal';
import ImmediateReleaseModal from '../../../components/BalancesModal';
import Drawer from '../../../components/Drawer';
import SuccessModal from '../../../components/SuccessModal';
import { PayrollModals } from '../components/PayrollModals';
import Buttons from '../../../components/Buttons';

import styles from './styles.module.css';

import { ActionsBalance } from '../../../redux/store/Balances';

import { api } from '../../../services/api';

import currencyFormatter from '../../../utils/currencyFormatter';
import convertBrazilianDate from '../../../utils/convertBrazilianDate';
import { hasAuthorization, profiles } from '../../../utils/hasAuthorization';
import { isMobile } from '../../../utils/breakpoints';
import { pusher } from '../../../utils/pusher';
import { isSubsidiaryCompanie } from '../../../utils/isSubsidiaryCompanie';
import { handleSnackbarStack } from '../../../utils/handleSnackbarStack';

import somapayLogoAnimation from '../../../lotties/somapayLogo.json';
import { pusherStatus } from '../../../enums/pusher';

import { ReactComponent as MinusIcon } from '../../../assets/minusIcon.svg';
import { ReactComponent as PlusIcon } from '../../../assets/plus-icon.svg';
import { ReactComponent as ExpressPaymentIcon } from '../../../assets/white-express-payment.svg';
import { useCountdown } from '../../../hooks/useValidationForUnformattedDate/useCountdown';
import useGeolocation from '../../../hooks/useGeolocation';

const PayrollSuccessfulScheduling = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useGeolocation();
  const { success, error } = handleSnackbarStack();

  const COUNTDOWN_TIME = 60;

  const importFileData = JSON.parse(sessionStorage.getItem('importFileData'));
  const paymentTypeDescription = JSON.parse(
    sessionStorage.getItem('paymentDescription'),
  );
  const nsu = sessionStorage.getItem('importFileNsu');
  const currentCompanyCode = sessionStorage.getItem('currentCompanyCode');
  const ACCESS_PROFILES = JSON.parse(sessionStorage.getItem('accessProfile'));
  const zeroProduct = JSON.parse(sessionStorage.getItem('zeroProduct'));
  const pixData = {
    base64QrCode: sessionStorage.getItem('payrollPix'),
    copyPasteCode: sessionStorage.getItem('payrollPixCopy'),
  };

  const [openModal, setOpenModal] = useState(false);
  const [currentPaymentSchedule, setCurrentPaymentSchedule] = useState('');
  const [circularLoading, setCircularLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [insufficientFunds, setInsufficientFunds] = useState(false);
  const [errorAuthorization, setErrorAuthorization] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [insufficientMoney, setInsufficientMoney] = useState(false);
  const [insufficientBalanceModal, setInsufficientBalanceModal] = useState(
    false,
  );
  const [openImmediateReleaseModal, setOpenImmediateReleaseModal] = useState(
    false,
  );
  const [
    openImmediateReleaseSuccessModal,
    setOpenImmediateReleaseSuccessModal,
  ] = useState(false);
  const [loadingRates, setLoadingRates] = useState(false);
  const [pinOnConfirmPayroll, setPinOnConfirmPayroll] = useState(false);

  const [channelData, setChannelData] = useState({});
  const [avaliableBalance, setAvaliableBalance] = useState({});
  const [rateValues, setRateValues] = useState({});

  const [statusPayroll, setStatusPayroll] = useState('');
  const [requestId, setRequestId] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [userEmail, setUserEmail] = useState('');

  const [pinValue, setPinValue] = useState(null);
  const [sendPinStatus, setSendPinStatus] = useState(null);
  const [employeesCount, setEmployeesCount] = useState(null);
  const [depositAccountEncodedKey, setDepositAccountEncodedKey] = useState('');

  const [countdownHasStarted, setCountDownHasStarted] = useState(false);
  const { currentTime } = useCountdown(COUNTDOWN_TIME, countdownHasStarted);

  const { accounts, accountSelected } = useSelector(state => state.account);

  const depositAccountIdSelected = accounts?.map(account => {
    if (account.encodedKey === depositAccountEncodedKey) {
      return account.id;
    }
    return null;
  });

  const hasMoreThanOneDepositAccount = accounts.length > 1;

  const {
    scheduleDate,
    paymentsAmount,
    totalFeeAmount,
    totalAmount,
    fileName,
    companyName,
  } = importFileData;

  const TOTAL_PAYROLL_AMOUNT = paymentsAmount + totalFeeAmount;
  const IS_RESCISION = '2';
  const isSubsidiary = isSubsidiaryCompanie();

  const EMAIL_MASKED = userEmail?.replace(
    /^(.)(.*)(.@.*)$/,
    (_, a, b, c) => a + b.replace(/./g, '*') + c,
  );

  const formatNumber = rawNumber =>
    '****'?.concat(rawNumber?.slice(rawNumber?.length - 4));

  const resetError = () => setErrorMessage('');

  const animationOptions = {
    loop: true,
    autoplay: true,
    animationData: somapayLogoAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const loadingPayment = sessionStorage.getItem('loadingPayment');

  const {
    PROCESSED_PAYROLL_SUCCESS,
    PROCESSING,
    PROCESSED_PAYROLL_ERROR,
  } = pusherStatus;

  const info = [
    { key: 'Empresa', value: companyName },
    { key: 'Data de Agendamento', value: convertBrazilianDate(scheduleDate) },
    {
      key: 'Valor da Folha',
      value: paymentsAmount?.toLocaleString('pt-BR', {
        minimumFractionDigits: 2,
        style: 'currency',
        currency: 'BRL',
      }),
    },
    {
      key: 'Tarifa',
      value: totalFeeAmount?.toLocaleString('pt-BR', {
        minimumFractionDigits: 2,
        style: 'currency',
        currency: 'BRL',
      }),
    },
  ];

  if (fileName !== 'companies_payments') {
    info.unshift({ key: 'Nome do Arquivo', value: fileName });
  }

  const disableActions = loadingPayment
    ? !hasAuthorization(ACCESS_PROFILES, [profiles.AUTHORIZER]) ||
      paymentTypeDescription?.paymentType === IS_RESCISION ||
      zeroProduct
    : !hasAuthorization(ACCESS_PROFILES, [profiles.AUTHORIZER]) ||
      insufficientMoney;

  const onNextClicked = () => {
    sessionStorage.removeItem('useTopBar');
    sessionStorage.removeItem('loadingPayment');
    dispatch(ActionsBalance.getBalances());
    history.push('/payroll');
  };

  const getAvaibleBalance = () => {
    const requestBody = {
      nsuList: [nsu],
    };

    api
      .post(
        `/employeesPayments/batch/authorize/balance/${currentCompanyCode}`,
        requestBody,
      )
      .then(({ data }) => {
        if (data.insufficientBalance) {
          setInsufficientMoney(true);
        }
      });
  };

  const getDataTable = () => {
    api
      .get(`/files/employeespayments/confirmAppointment/${nsu}`)
      .then(({ data }) => {
        setEmployeesCount(data.totalSize);
        setDepositAccountEncodedKey(data.depositAccountId);
      });
  };

  const getRates = () => {
    const requestOptions = {
      headers: {
        depositAccountId: accountSelected.encodedKey,
        accountOrigin: accountSelected?.origin,
      },
    };

    api
      .get(
        `/employeesPayments/expressPayment/fee/${currentCompanyCode}/${nsu}`,
        requestOptions,
      )
      .then(({ data }) => {
        const { errorMessage, missingBalance } = data;

        if (pinOnConfirmPayroll) {
          preAuthorizePayroll(false);
        }

        if (errorMessage && missingBalance) {
          setInsufficientFunds(true);
        }
        setRateValues(data);
      })
      .finally(() => setLoadingRates(false));
  };

  const confirmAuthorization = () => {
    setLoadingButton(true);

    const requestOptions = {
      headers: {
        requestId,
        pin: pinValue,
        branding: process.env.REACT_APP_BRAND_FOR_LOGIN_VALIDATION,
        ...location,
      },
    };

    api
      .put(`employeesPayments/${nsu}/authorize`, '', requestOptions)
      .then(() => {
        setInsufficientBalanceModal(false);
      })
      .catch(
        ({
          response: {
            data: { errors },
          },
        }) => {
          if (errors[0]?.errorReason === 'FILE_ALREADY_PROCESSED') {
            setErrorAuthorization(true);
          }

          setErrorMessage(errors[0]?.errorDetail);
        },
      )
      .finally(() => {
        setLoadingButton(false);
      });
  };

  const onPaymentClick = () => {
    setLoadingModal(true);
    setErrorMessage('');
    setPinValue('');
    setChannelData({});
    setCurrentPaymentSchedule(
      `Pagamento agendado com sucesso para o dia ${moment(scheduleDate).format(
        'DD/MM/YYYY [às] HH:mm',
      )}. Caso deseje antecipar o pagamento, utilize a opção de liberação imediata.`,
    );

    const requestBody = {
      nsuList: [nsu],
    };

    api
      .post(
        `/employeesPayments/batch/authorize/balance/${currentCompanyCode}`,
        requestBody,
      )
      .then(({ data }) => {
        if (data.insufficientBalance) {
          setAvaliableBalance(data);
          setInsufficientBalanceModal(true);
        } else if (pinOnConfirmPayroll) {
          preAuthorizePayroll(false, () => setOpenModal(true));
        }
      })
      .finally(() => setLoadingModal(false));
  };

  const preAuthorizePayroll = (isTryingSendCodeBySms, callback) => {
    const nsuList = [Number(nsu)];

    const requestBody = {
      nsuList,
      emailNotReceived: isTryingSendCodeBySms,
    };

    const requestConfig = {
      headers: {
        branding: process.env.REACT_APP_BRAND_FOR_LOGIN_VALIDATION,
      },
    };

    setCountDownHasStarted(true);

    setTimeout(() => {
      setCountDownHasStarted(false);
    }, COUNTDOWN_TIME * 1000);

    api
      .put('/employeesPayments/pre-authorize', requestBody, requestConfig)
      .then(({ data }) => {
        setRequestId(data.requestId);
        setUserEmail(data.email);
        setSendPinStatus(data.status);

        if (data?.phoneNumber && data?.status === 'SMS_SENT') {
          success(
            `O SMS foi enviado para o número: ${formatNumber(
              data?.phoneNumber,
            )}`,
          );
        }

        if (callback) callback();
      })
      .catch(err => error(err.response?.data));
  };

  const immediateRelease = (status, nsu) => {
    setErrorMessage('');
    setPinValue('');
    setInsufficientFunds(false);
    setLoadingRates(true);
    setOpenImmediateReleaseModal(true);
    setStatusPayroll(status);
    getRates(nsu);
  };

  const onConfirmImmediateRelease = () => {
    setLoadingButton(true);

    const payrollId = rateValues.nsu;

    const requestOptions = {
      headers: {
        requestId,
        pin: pinValue,
        accountOrigin: accountSelected?.origin,
      },
    };

    api
      .post(`employeesPayments/expressPayment/${payrollId}`, '', requestOptions)
      .then(() => {
        setOpenImmediateReleaseSuccessModal(true);
        setOpenImmediateReleaseModal(false);
        setInsufficientFunds(false);
      })
      .catch(
        ({
          response: {
            data: { errors },
          },
        }) => {
          setErrorMessage(errors[0]?.errorDetail);
        },
      )
      .finally(() => {
        setLoadingButton(false);
      });
  };

  const verifyNecessityOfPinForAuthorizePayroll = () => {
    api.get(`/companies/${currentCompanyCode}/search`).then(({ data }) => {
      setPinOnConfirmPayroll(data.confirmPayroll);
    });
  };

  const handlePayrollSuccess = () => {
    setOpenModal(false);
    dispatch(ActionsBalance.getBalances());
  };

  const goToDeposits = () => {
    history.push('/cash-in');
  };

  const formatedAmount = value =>
    value?.toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      style: 'currency',
      currency: 'BRL',
    });

  const handleChangePinValue = e => {
    setPinValue(e.target.value);
  };

  const handleShowPixModal = () => {
    NiceModal.show('payroll-pix-modal', { 
      qrCodeBase64:pixData.base64QrCode,
      qrCodeCopyAndPaste: pixData.copyPasteCode,
      nsu: pixData?.nsu
     });
  };

  const onNumberCopy = () => {
    success('Código copiado para a área de transferência.');
  };

  const formatFeeAmount = rateValues.feeAmount
    ? currencyFormatter(rateValues.feeAmount)
    : 'R$ 0,00';

  const formatPayrollValue = rateValues.totalAmount
    ? currencyFormatter(rateValues.totalAmount)
    : 'R$ 0,00';

  const totalPayrollAmount = rateValues.totalAmount + rateValues.feeAmount;

  const formatTotalPayrollAmount = totalPayrollAmount
    ? currencyFormatter(totalPayrollAmount)
    : 'R$ 0,00';

  const formatedMissingBalance = rateValues.missingBalance
    ? currencyFormatter(rateValues.missingBalance)
    : 'R$ 0,00';

  const formatedBalance = rateValues?.availableBalance
    ? currencyFormatter(rateValues?.availableBalance)
    : 'R$ 0,00';

  useEffect(() => {
    const channel = pusher.subscribe(currentCompanyCode);

    channel.bind('AUTHORIZATION_PAYROLL', function(data) {
      setChannelData(data);

      if (data.status === PROCESSED_PAYROLL_SUCCESS)
        sessionStorage.setItem('loadingPayment', true);
    });

    sessionStorage.removeItem('selectedEmployees');

    verifyNecessityOfPinForAuthorizePayroll();

    return () => {
      pusher.unsubscribe(currentCompanyCode);
    };
  }, []);

  useEffect(() => {
    const channel = pusher.subscribe(nsu);

    channel.bind('CASH_IN_QR_CODE_PAID_TO_PAYMENT_FILE', ({ status }) => {
      if (status === 'CASH_IN_QR_CODE_EMPLOYEE_PAYMENT_PROCESSED') {
        NiceModal.hide('payroll-pix-modal');
        setTimeout(
          () => NiceModal.show('payroll-pix-confirmation-modal'),
          1000,
        );
      }
    });

    return () => {
      pusher.unsubscribe(nsu);
      clearTimeout();
    };
  }, []);

  useEffect(() => {
    getDataTable();
    getAvaibleBalance();
  }, []);

  return (
    <div>
      <Typography className={isMobile ? styles.hidden : styles.titleStyle}>
        {loadingPayment ? 'Agendado e autorizado' : 'Aguardando autorização'}
      </Typography>

      <div
        className={isMobile ? styles.mobileContentStyle : styles.contentStyle}
      >
        <div className={styles.approvalDivStyle}>
          <Grid className={styles.subheader} item xs={12}>
            <AccessTimeRoundedIcon
              className={
                loadingPayment ? styles.clockBlueIcon : styles.clockOrangeIcon
              }
            />

            <Typography
              className={
                loadingPayment ? styles.textBlueBigger : styles.textOrangeBigger
              }
            >
              {loadingPayment ? (
                'Pagamento agendado e autorizado.'
              ) : (
                <>
                  Pagamento agendado
                  <br /> aguardando autorização.
                </>
              )}
            </Typography>

            <Typography
              className={
                loadingPayment
                  ? styles.smallBlueBigger
                  : styles.smallOrangeBigger
              }
            >
              {loadingPayment
                ? 'Com saldo suficiente em conta, o pagamento será realizado na data agendada.'
                : 'É necessário autorizar para o pagamento ser executado.'}
            </Typography>

            <div
              className={isMobile ? styles.nsuMobileContainer : styles.hidden}
            >
              <div className={styles.nsuNumber}>NSU {nsu}</div>

              <CopyToClipboard text={nsu}>
                <Button className={styles.nsuNumberCopy} onClick={onNumberCopy}>
                  <FileCopyOutlinedIcon className={styles.nsuNumberCopyIcon} />
                  Copiar NSU
                </Button>
              </CopyToClipboard>
            </div>

            <Box className={styles.buttonsContainer}>
              <Buttons.PrimaryButton
                fullWidth={isMobile}
                className={styles.actionButton}
                size={isMobile ? 'large' : 'medium'}
                icon={<ExpressPaymentIcon />}
                color="secondary"
                title={
                  loadingPayment
                    ? 'Liberar o pagamento agora'
                    : 'Autorizar agora'
                }
                onClick={loadingPayment ? immediateRelease : onPaymentClick}
                disabled={disableActions}
              />

              <Buttons.PrimaryButton
                color="primary"
                fullWidth={isMobile}
                className={styles.actionButton}
                size={isMobile ? 'large' : 'medium'}
                onClick={handleShowPixModal}
                title="Pagar via Pix"
              />

              {isMobile && (
                <Buttons.PrimaryButton
                  fullWidth={isMobile}
                  className={styles.actionButton}
                  size={isMobile ? 'large' : 'medium'}
                  color="primary"
                  title="Gerenciar pagamentos"
                  onClick={onNextClicked}
                />
              )}
            </Box>
          </Grid>
        </div>

        <InfoGrid
          nsu={nsu}
          scheduleDate={`${moment(scheduleDate).format(
            'DD/MM/YYYY',
          )} às ${moment(scheduleDate).format('LT')}`}
          paymentType={paymentTypeDescription?.paymentTypeDescription}
          fileName={fileName}
          employeesCount={employeesCount}
          totalValue={formatedAmount(totalAmount)}
          hidden={isMobile}
          depositAccount={depositAccountIdSelected}
          hasMoreThanOneDepositAccount={hasMoreThanOneDepositAccount}
        />

        <ProgressToolBar
          disableBack
          onNextClicked={onNextClicked}
          nextButtonText="Gerenciar pagamentos"
          hidden={isMobile}
        />
      </div>

      <InsufficientBalanceModal
        pinOnConfirmPayroll={pinOnConfirmPayroll}
        codeVerification={!avaliableBalance?.insufficientBalance}
        codeValue={pinValue}
        handleChangeCodeValue={handleChangePinValue}
        errorMessage={errorMessage}
        emailLabel={EMAIL_MASKED}
        title={
          avaliableBalance?.insufficientBalance
            ? 'Saldo insuficiente'
            : 'Autorizar pagamento?'
        }
        text={
          avaliableBalance?.insufficientBalance
            ? 'Você não possui saldo suficiente para autorizar o pagamento.'
            : 'Ao autorizar, o pagamento desta importação será processado e não poderá ser desfeito.'
        }
        open={insufficientBalanceModal}
        confirmButtonText={
          avaliableBalance?.insufficientBalance
            ? 'Fazer depósito'
            : isMobile
            ? 'Autorizar'
            : 'Autorizar Pagamento'
        }
        cancelButtonText="Cancelar"
        onCancelButtonClicked={() => setInsufficientBalanceModal(false)}
        firstLabel="Seu Saldo"
        firstValue={currencyFormatter(avaliableBalance.balance)}
        secondLabel="Valor da Folha"
        secondValue={currencyFormatter(avaliableBalance.fileAmount)}
        thirdLabel="Diferença"
        thirdValue={currencyFormatter(avaliableBalance.difference)?.replace(
          '-',
          '',
        )}
        alertText="Deposite o valor pendente para conseguir realizar o pagamento."
        onConfirmClicked={
          avaliableBalance?.insufficientBalance
            ? () => history.push('/cash-in')
            : confirmAuthorization
        }
        icon={<MinusIcon />}
        loadingButton={loadingButton}
        disabledConfirmButton={
          loadingButton ||
          (insufficientFunds &&
            (!hasAuthorization(ACCESS_PROFILES, [
              profiles.ADMIN,
              profiles.FINANCIAL,
            ]) ||
              isSubsidiary))
        }
      />

      <AuthorizePayrollModal
        pinOnConfirmPayroll={pinOnConfirmPayroll}
        codeVerification={channelData?.status !== PROCESSED_PAYROLL_SUCCESS}
        codeValue={pinValue}
        handleChangeCodeValue={handleChangePinValue}
        errorMessage={errorMessage}
        emailLabel={EMAIL_MASKED}
        open={openModal}
        title={
          channelData?.status === PROCESSED_PAYROLL_ERROR
            ? 'Problema na autorização'
            : channelData?.status === PROCESSING
            ? 'Autorização em processamento...'
            : channelData?.status === PROCESSED_PAYROLL_SUCCESS
            ? 'Pagamento Autorizado!'
            : errorAuthorization
            ? 'Um outro usuário já realizou está ação.'
            : 'Autorizar este pagamento?'
        }
        text={
          channelData?.status === PROCESSED_PAYROLL_ERROR
            ? channelData.statusDescription
            : channelData?.status === PROCESSING
            ? 'O processamento pode levar alguns minutos. Após a conclusão, você pode voltar a usar a plataforma normalmente.'
            : channelData?.status === PROCESSED_PAYROLL_SUCCESS
            ? currentPaymentSchedule
            : errorAuthorization
            ? 'A autorização para o pagamento desta folha já foi dada e será executada na data agendada.'
            : 'Ao autorizar, o pagamento será executado na data agendada.'
        }
        confirmButtonText={
          channelData?.status === PROCESSING
            ? `${channelData?.percentage || '0'} %`
            : channelData?.status === PROCESSED_PAYROLL_SUCCESS
            ? 'Fechar'
            : channelData?.status === PROCESSED_PAYROLL_ERROR
            ? 'Continuar'
            : errorAuthorization
            ? 'Entendi'
            : isMobile
            ? 'Autorizar'
            : 'Autorizar Pagamento'
        }
        onConfirmClicked={
          channelData?.status === PROCESSED_PAYROLL_SUCCESS
            ? () => {
                handlePayrollSuccess();
              }
            : errorAuthorization ||
              channelData?.status === PROCESSED_PAYROLL_ERROR
            ? () => setOpenModal(false)
            : confirmAuthorization
        }
        cancelButtonText={
          !(
            channelData?.status === PROCESSED_PAYROLL_SUCCESS ||
            errorAuthorization ||
            channelData?.status === PROCESSED_PAYROLL_ERROR
          ) && 'Cancelar'
        }
        onCancelButtonClicked={() => setOpenModal(false)}
        progressLoading={channelData?.status === PROCESSING}
        progress={channelData?.percentage}
        sendPinStatus={sendPinStatus}
        countdownTime={currentTime}
        countdownHasStarted={countdownHasStarted}
        handleTrySendCodeBySms={() => preAuthorizePayroll(true)}
      />

      <ImmediateReleaseModal
        pinOnConfirmPayroll={pinOnConfirmPayroll}
        codeVerification={!insufficientFunds}
        codeValue={pinValue}
        handleChangeCodeValue={handleChangePinValue}
        errorMessage={errorMessage}
        resetError={resetError}
        emailLabel={EMAIL_MASKED}
        open={openImmediateReleaseModal}
        onCloseDialog={() => setOpenImmediateReleaseModal(false)}
        title={
          insufficientFunds
            ? 'Saldo insuficiente'
            : 'Liberar este pagamento imediatamente?'
        }
        text={
          insufficientFunds
            ? 'Você não possui saldo suficiente para liberação imediata do pagamento desta folha.'
            : 'O pagamento será efetuado em até 1 hora, a partir do momento da confirmação e não poderá ser cancelado.'
        }
        cancelButtonText="Cancelar"
        onCancelButtonClicked={() => setOpenImmediateReleaseModal(false)}
        confirmButtonText={
          insufficientFunds
            ? 'Fazer depósito'
            : isMobile
            ? 'Liberar'
            : 'Liberar pagamento'
        }
        onConfirmClicked={
          insufficientFunds ? goToDeposits : onConfirmImmediateRelease
        }
        loading={loadingRates}
        loadingButton={loadingButton}
        firstLabel={insufficientFunds ? 'Seu saldo' : 'Folha'}
        firstValue={insufficientFunds ? formatedBalance : formatPayrollValue}
        secondLabel={insufficientFunds ? 'Valor da Folha' : 'Tarifa'}
        secondValue={insufficientFunds ? formatPayrollValue : formatFeeAmount}
        thirdLabel={insufficientFunds ? 'Diferença' : 'Total da Folha'}
        thirdValue={
          insufficientFunds ? formatedMissingBalance : formatTotalPayrollAmount
        }
        alertText={
          insufficientFunds
            ? 'Deposite o valor pendente para conseguir realizar a liberação.'
            : 'A tarifa será cobrada após a execução do pagamento.'
        }
        icon={insufficientFunds ? <MinusIcon /> : <PlusIcon />}
        disabledConfirmButton={
          loadingButton ||
          (avaliableBalance?.insufficientBalance &&
            (!hasAuthorization(ACCESS_PROFILES, [
              profiles.ADMIN,
              profiles.FINANCIAL,
            ]) ||
              isSubsidiary))
        }
        sendPinStatus={sendPinStatus}
      />

      <LoadingModal
        open={loadingModal}
        title="Aguarde alguns instantes..."
        image={<Lottie options={animationOptions} height={120} width={120} />}
      />

      <SuccessModal
        open={openImmediateReleaseSuccessModal}
        onCloseDialog={onNextClicked}
        title={
          statusPayroll === 'Aguardando autorização'
            ? 'Pagamento autorizado e liberado com sucesso!'
            : 'Pagamento liberado com sucesso!'
        }
        closeButtonText="Fechar"
        text="Em até 1 hora todos os pagamentos serão realizados."
      />

      <Drawer
        title="Passo a passo"
        hidden={isMobile}
        content={
          <ContentDrawer
            onPaymentClick={onPaymentClick}
            onClick={onPaymentClick}
            paymentTypeDescription={
              paymentTypeDescription?.paymentTypeDescription
            }
            insufficientMoney={insufficientMoney}
            circularLoading={circularLoading}
            setCircularLoading={setCircularLoading}
            fee={formatedAmount(totalFeeAmount)}
            payrollAmount={formatedAmount(paymentsAmount)}
            nsu={nsu}
            confirmAuthorization={confirmAuthorization}
            formatTotalPayrollAmount={formatedAmount(TOTAL_PAYROLL_AMOUNT)}
            handleChangePinValue={handleChangePinValue}
            EMAIL_MASKED={EMAIL_MASKED}
            setPinValue={setPinValue}
            pinValue={pinValue}
            sendPinStatus={sendPinStatus}
          />
        }
      />

      <PayrollModals />
    </div>
  );
};

export default PayrollSuccessfulScheduling;
