import React from 'react';
import { useHistory } from 'react-router-dom';

import { Button, Typography } from '@material-ui/core';
import EditRoundedIcon from '@material-ui/icons/EditRounded';

import styles from '../styles.module.css';
import { api } from '../../../../services/api';

import ResponsiveColorStatus from './ResponsiveColorStatus';

function ResponsiveTedDetails({
  creationUserName,
  formatedCreationDate,
  depositProcessedOn,
  amountFormatted,
  notes,
  receipt,
  destination,
  originName,
  scheduleDateFormatted,
  origin,
  ted,
  id,
}) {
  const history = useHistory();

  const handleClickEditDeposit = () => {
    api.get(`/cashIn/teds/id/${id}`).then(({ data }) => {
      sessionStorage.setItem(
        'useTopBar',
        JSON.stringify({
          name: 'Editar TED',
          step: 1,
          route: '/cash-in',
        }),
      );

      sessionStorage.setItem('editDepositInform', JSON.stringify(data));
      history.push(`/cash-in/inform-transfer-Deposit`, { byScreen: true });
    });
  };

  return (
    <div>
      <div className={styles.editButtonContainer}>
        <Button className={styles.editButton} onClick={handleClickEditDeposit}>
          <EditRoundedIcon className={styles.editIcon} />
          Editar
        </Button>
      </div>

      <div className={styles.dataContainer}>
        <ResponsiveColorStatus status={ted.status} />

        <div className={styles.responsiveDataGrid}>
          <Typography className={styles.label}>Valor</Typography>
          <Typography className={styles.amountValue}>
            {amountFormatted}
          </Typography>
        </div>

        <div className={styles.responsiveDataGrid} style={{ display: 'flex' }}>
          <div className={styles.marginRight}>
            <Typography className={styles.label}>Data de depósito</Typography>
            <Typography className={styles.defaultText}>
              {scheduleDateFormatted}
            </Typography>
          </div>
          <div>
            <Typography className={styles.label}>3 últimos dígitos</Typography>
            <Typography className={styles.defaultText}>
              {receipt || '-'}
            </Typography>
          </div>
        </div>

        <div className={styles.responsiveDataGrid}>
          <Typography className={styles.label}>Observação</Typography>
          <Typography className={styles.defaultText}>
            {notes || 'Nenhuma'}
          </Typography>
        </div>
      </div>

      <div className={styles.dataContainer}>
        <div className={styles.responsiveDataGrid}>
          <Typography className={styles.biggerText}>Origem</Typography>
        </div>

        <div className={styles.responsiveDataGrid} style={{ display: 'flex' }}>
          <div className={styles.marginRight}>
            <Typography className={styles.label}>Banco</Typography>
            <Typography className={styles.defaultText}>
              {origin?.bankName}
            </Typography>
          </div>
          <div>
            <Typography className={styles.label}>Agência</Typography>
            <Typography className={styles.defaultText}>
              {origin?.agency}
            </Typography>
          </div>
        </div>

        <div className={styles.responsiveDataGrid} style={{ display: 'flex' }}>
          <div className={styles.marginRight}>
            <Typography className={styles.label}>Conta</Typography>
            <Typography className={styles.defaultText}>
              {origin?.account}
            </Typography>
          </div>
          <div>
            <Typography className={styles.label}>CPF</Typography>
            <Typography className={styles.defaultText}>
              {origin?.cpfCnpj}
            </Typography>
          </div>
        </div>

        <div className={styles.responsiveDataGrid}>
          <Typography className={styles.label}>Titular</Typography>
          <Typography className={styles.defaultText}>{originName}</Typography>
        </div>

        <div className={styles.divider} />

        <div className={styles.responsiveDataGrid}>
          <Typography className={styles.biggerText}>Destino</Typography>
        </div>

        <div className={styles.responsiveDataGrid} style={{ display: 'flex' }}>
          <div className={styles.marginRight}>
            <Typography className={styles.label}>Banco</Typography>
            <Typography className={styles.defaultText}>
              {destination?.bankName}
            </Typography>
          </div>
          <div>
            <Typography className={styles.label}>Agência</Typography>
            <Typography className={styles.defaultText}>
              {destination?.agency}
            </Typography>
          </div>
        </div>

        <div className={styles.responsiveDataGrid} style={{ display: 'flex' }}>
          <div className={styles.marginRight}>
            <Typography className={styles.label}>Conta</Typography>
            <Typography className={styles.defaultText}>
              {destination?.account}
            </Typography>
          </div>
          <div>
            <Typography className={styles.label}>CNPJ</Typography>
            <Typography className={styles.defaultText}>
              {destination?.cpfCnpj}
            </Typography>
          </div>
        </div>

        <div className={styles.responsiveDataGrid}>
          <Typography className={styles.label}>Razão Social</Typography>
          <Typography className={styles.defaultText}>
            {destination?.name}
          </Typography>
        </div>
      </div>

      <div className={styles.dataContainer}>
        <div className={styles.responsiveDataGrid} style={{ display: 'flex' }}>
          <div className={styles.marginRight}>
            <Typography className={styles.label}>Criação</Typography>
            <Typography className={styles.defaultText}>
              {creationUserName}
            </Typography>
          </div>
          <div>
            <div className={styles.marginRight}>
              <Typography className={styles.label}>
                3 últimos dígitos
              </Typography>
              <Typography className={styles.defaultText}>{receipt}</Typography>
            </div>
          </div>
        </div>

        <div className={styles.responsiveDataGrid} style={{ display: 'flex' }}>
          <div className={styles.marginRight}>
            <Typography className={styles.label}>Data de criação</Typography>
            <Typography className={styles.defaultText}>
              {formatedCreationDate}
            </Typography>
          </div>
          <div>
            <Typography className={styles.label}>
              Data de confirmação
            </Typography>
            <Typography className={styles.defaultText}>
              {depositProcessedOn}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResponsiveTedDetails;
