import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from '@material-ui/lab';
import {
  Badge,
  Box,
  Drawer,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core';

import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import ReportRoundedIcon from '@material-ui/icons/ReportRounded';
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import LinearProgressBar from '../../SharedComponents/ProgressBar';
import { ActionsPayrollDetails } from '../../../redux/store/Payroll/PayrollDetails';
import { ReactComponent as DownloadButton } from '../../../assets/download-button.svg';


import { useStyles } from './styles';
import { style } from './../../../pages/Extract/styles';

export default function DownloadingProgress() {
  const history = useHistory();
  const dispatch = useDispatch();
  const styles = useStyles();
  const {downloadingNSUProof} = useSelector(state => state.payroll.payrollDetails);

  const downloadsConcluded = downloadingNSUProof.filter((item) => item.downloadReceiptsPercentage === 100);
  const downloadsInProgress = downloadingNSUProof.filter((item) => item.downloadReceiptsPercentage !== 100);
  const downloadError = downloadingNSUProof.some((item) => item.error === true);

  const [open, setOpen] = useState(false);

  const toggleMenu = () => {
    setOpen(!open);
  };

  function goToSeeDetails(nsu) {
    dispatch(ActionsPayrollDetails.setNsuFromProgressBar(nsu));
    setOpen(false);
    history.push(`/payroll/details`);
  }

  function handleClose(nsu) {
    dispatch(ActionsPayrollDetails.removeNsuFromProgressBar(nsu));
  }

  useEffect(() => {
    if (downloadsConcluded.length > 0) {
      setTimeout(() => {
        downloadsConcluded.map(item => {
          dispatch(ActionsPayrollDetails.removeNsuFromProgressBar(item?.nsu));
        })
      }, 5000);
    }
  }, [downloadingNSUProof]);

  const renderIcon = () => (
    <Tooltip arrow title="Downloads">
      <IconButton
        onClick={toggleMenu}
      >
        <Badge color="primary">
          <DownloadButton />
        </Badge>
        {downloadError
          ? <ErrorOutlineRoundedIcon className={styles.iconErrorTop} />
          : downloadsInProgress.length < 1
            ? <CheckIcon className={styles.iconAllDownloadConcluded} />
            : <Typography
                className={styles.count}
                variant='caption'>
                {downloadingNSUProof.length}
              </Typography>}

        </IconButton>
        
    </Tooltip>
  );

  return (
    <>
      {renderIcon()}
      <Drawer
        classes={{
          root: styles.drawerDownload,
        }}
        elevation={10}
        id="notifications-drawer"
        anchor="right"
        open={open}
        onClose={toggleMenu}
        PaperProps={{
          className: styles.root,
        }}
        BackdropProps={{ invisible: true }}
      >
        {downloadingNSUProof.map(nsu => (
          <Box key={nsu.nsu} className={styles.positionWrapper}>
            {nsu.downloadReceiptsPercentage < 100
              ? <>
                <LinearProgressBar
                  title={`Gerando comprovantes - NSU: ${nsu.nsu}`}
                  progressValue={nsu.downloadReceiptsPercentage}
                  width={385}
                  height={60}
                  titleTop={true}
                />
                <ArrowForwardIosIcon
                  className={styles.iconStyle}
                  style={{ color: '#52197F' }}
                  onClick={() => goToSeeDetails(nsu.nsu)} />
              </>
              : <Box className={styles.concludedMain}>
                  <Box className={styles.concludedBox}>
                    <Typography variant='body1'>Geração de comprovantes</Typography>{}
                    <Box>
                      {nsu.error
                        ? <Box className={'ErrorTeste', styles.error} >
                            <ReportRoundedIcon className={styles.iconError} />
                            <Typography variant='caption' className={styles.errorText} >Ocorreu um erro na geração dos comprovantes.</Typography>
                        </Box>
                      : <Box>
                          <CheckIcon className={styles.iconCheck} />
                          <Typography variant='caption' className={styles.concludedText} >Arquivos gerados com sucesso.</Typography>
                        </Box>}
                    </Box>
                  </Box>
                  <CloseIcon
                    className={styles.iconStyle}
                    style={{ color: '#52197F' }}
                    onClick={() => handleClose(nsu.nsu)}/>
                </Box>
            }
          </Box>
        ))}
      </Drawer>
    </>
  );
}
