import React from 'react';
import { Box, Button, CircularProgress, Typography } from '@material-ui/core';
import LinearProgressBar from '../../../../../components/SharedComponents/ProgressBar';
import { ReactComponent as Files } from '../../../../../assets/files.svg';
import { ReactComponent as FolderZip } from '../../../../../assets/folderZip.svg';

import styles from './styles.module.css';

export default function BottomBarActionsTable({
  selected,
  cancelText = 'Cancelar',
  onCancelClick,
  confirmText,
  onConfirmClick,
  multipleRowsSelected,
  progressBar,
  progressValue,
  selectedAll,
  excludedLinesList,
  totalSize
}) {
  return (
    <div className={selected.length !== 0 || progressBar ? styles.container : styles.hidden}>
      {progressBar
        ? <Box sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
          <LinearProgressBar
            title="Gerando comprovantes"
            progressValue={progressValue}
            width={385}
            height={60}
            titleTop={false}
          />
          <CircularProgress />
        </Box>
        : <>
            <div className={styles.selecteds}>
              <Typography className={styles.count}>
                {selectedAll ? totalSize - excludedLinesList.length : selected.length}
              </Typography>
              <Typography className={styles.countLabel}>
                {multipleRowsSelected ? 'Selecionados' : 'Selecionado'}
              </Typography>
            </div>
            <Box className={styles.actions}>
              <Button
                variant="outlined"
                color="primary"
                className={styles.cancelButton}
                onClick={onCancelClick}
              >
                {cancelText}
              </Button>
              <Box className={styles.divider} />
              <Button 
                color="primary"
                className={styles.button} onClick={() => onConfirmClick(false)}>
                <FolderZip className={styles.iconFolderZip} />
                {'Baixar individualmente'}
              </Button>
              <Button 
                color="primary"
                className={styles.button} onClick={() => onConfirmClick(true)}>
                <Files className={styles.iconFiles} />
                {'Baixar agrupado'}
              </Button>
            </Box>
          </>
        }
    </div>
  );
}
