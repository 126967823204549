import React, { useEffect, useState } from 'react';
import LinearProgress, { LinearProgressProps } from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core';

interface IProgressBarProps {
  title: string;
  progressValue: number;
  width: number;
  height: number;
  titleTop: boolean;
}

const useStyles = makeStyles({
  root: {
    borderRadius: 12,
    height: 6,
    backgroundColor: '#EEE8F2',
  },
  bar: {
    borderRadius: 12,
    backgroundColor: '#52197F',
  },
});

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
  const classes = useStyles();
  return (
    <Box display="flex" alignItems="center" width="100%">
      <Box width="100%" mr={1}>
      <LinearProgress
          variant="determinate"
          {...props}
          classes={{
            root: classes.root,
            bar: classes.bar,
          }}
        />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

export default function LinearProgressBar({
  title,
  progressValue,
  width,
  height,
  titleTop,
}: IProgressBarProps) {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    setProgress(progressValue);
  }, [progressValue]);

  return (
    <Box
      style={{
        width: `${width}px`,
        height: `${height}px`,
        display: 'flex',
        flexDirection: titleTop ? 'column' : 'row',
        alignItems: titleTop ? 'flex-start' : 'center',
        justifyContent: 'center',
        gap: titleTop ? '4px' : '16px',
      }}
    >
      <Typography
        variant="body2"
        color="textSecondary"
        style={{ whiteSpace: 'nowrap' }}
        >
          {title}
        </Typography>
      <LinearProgressWithLabel value={progress} />
    </Box>
  );
}
