import React from 'react';
import { Controller } from 'react-hook-form';

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';

import { Alert, Autocomplete } from '@material-ui/lab';

import { useInsertBankData } from './Hooks/useInsertBankData';

export default function InsertBankDataDialogResponsive() {
  const {
    banksList,
    insertBankDataDialog,
    insertBankDataForm,
    employeeName,
    employeeBankData,
  } = useInsertBankData();

  return (
    <Dialog
      open={insertBankDataDialog.isOpen}
      onClose={insertBankDataDialog.handleClose}
      fullWidth
      fullScreen="sm"
    >
      <Box
        display="flex"
        flexDirection="column"
        py={2}
        px={3}
        sx={{ gap: '0.5rem' }}
      >
        <Typography variant="h6" style={{ fontWeight: 700 }}>
          Inserir dados bancários
        </Typography>
        <Typography variant="subtitle2" style={{ color: '#666666' }}>
          Enviaremos o pagamento desse colaborador para a conta bancária
          inserida abaixo:
        </Typography>
      </Box>

      <DialogContent>
        <Box
          mb={4}
          display="flex"
          flexDirection="column"
          sx={{ gap: '1.5rem' }}
        >
          <Box flexDirection="column" sx={{ gap: '0.375rem' }}>
            <Typography variant="subtitle2" style={{ color: '#808080' }}>
              Inserir conta bancária para:
            </Typography>
            <Typography variant="subtitle2">{employeeName}</Typography>
          </Box>

          <Alert severity="info">
            A conta inserida deverá ser do colaborador.
          </Alert>
        </Box>

        <form
          onSubmit={insertBankDataForm.handleSubmit(
            insertBankDataForm.onSubmit,
          )}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Controller
                name="bank"
                control={insertBankDataForm.control}
                render={({ field }) => (
                  <Autocomplete
                    loading={banksList.isLoading}
                    options={banksList.data}
                    onChange={(event, option) => field.onChange(option)}
                    getOptionLabel={option => option?.name}
                    renderOption={option => (
                      <>
                        {option.name} - {option.code}
                      </>
                    )}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Banco"
                        variant="outlined"
                        size="small"
                        error={insertBankDataForm.errors?.bank}
                        helperText={insertBankDataForm.errors.bank?.message}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {banksList.isLoading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                        {...field}
                      />
                    )}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={8} lg={8}>
              <Controller
                name="agency"
                control={insertBankDataForm.control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    label="Agência"
                    variant="outlined"
                    size="small"
                    error={insertBankDataForm.errors?.agency}
                    helperText={insertBankDataForm.errors.agency?.message}
                    {...field}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Controller
                name="agencyDigit"
                control={insertBankDataForm.control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    label="Dígito da agência"
                    variant="outlined"
                    size="small"
                    error={insertBankDataForm.errors?.agencyDigit}
                    helperText={insertBankDataForm.errors.agencyDigit?.message}
                    inputProps={{ maxLength: 1 }}
                    {...field}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={8} lg={8}>
              <Controller
                name="account"
                control={insertBankDataForm.control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    label="Número da conta"
                    variant="outlined"
                    size="small"
                    error={insertBankDataForm.errors?.account}
                    helperText={insertBankDataForm.errors.account?.message}
                    {...field}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Controller
                name="accountDigit"
                control={insertBankDataForm.control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    label="Dígito da conta"
                    variant="outlined"
                    size="small"
                    error={insertBankDataForm.errors?.accountDigit}
                    helperText={insertBankDataForm.errors.accountDigit?.message}
                    inputProps={{ maxLength: 1 }}
                    {...field}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Controller
                name="accountType"
                control={insertBankDataForm.control}
                render={({ field }) => (
                  <TextField
                    select
                    fullWidth
                    label="Tipo da conta"
                    variant="outlined"
                    size="small"
                    error={insertBankDataForm.errors?.accountType}
                    helperText={insertBankDataForm.errors.accountType?.message}
                    {...field}
                  >
                    <MenuItem value="CC">Conta corrente</MenuItem>
                    <MenuItem value="CP">Conta poupança</MenuItem>
                  </TextField>
                )}
              />
            </Grid>
          </Grid>

          <Box
            display="flex"
            justifyContent="flex-end"
            mt={4}
            sx={{ gap: '1rem' }}
          >
            <Button
              variant="text"
              color="primary"
              onClick={insertBankDataDialog.handleClose}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="contained"
              style={{
                color: '#FFFFFF',
                backgroundColor: '#E54217',
                borderRadius: '0.375rem',
              }}
            >
              {employeeBankData.isLoading ? (
                <CircularProgress size={18} style={{ color: '#FFFFFF' }} />
              ) : (
                'Salvar'
              )}
            </Button>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
}
