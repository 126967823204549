import React from 'react';
import { Provider } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import './globalStyles.css';

import { PersistGate } from 'redux-persist/integration/react';
import NiceModal from '@ebay/nice-modal-react';
import { SnackbarProvider } from 'notistack';
import store, { persistor } from './redux';
import Routes from './routes';
import { isMobile } from './utils/breakpoints';
import SnackbarProviderDeprecated from './context/Snackbar';
import CustomSnackbarDeprecated from './components/CustomSnackbar';
import SuccessModalContextProvider from './context/SuccessModalContext';
import ModalStandardSuccess from './components/ModalStandardSuccess';
import ModalProcessAbandonment from './components/ModalProcessAbandonment';
import { ModalProcessBlockProvider } from './context/ModalProcessBlock';

const GlobalCss = withStyles({
  // @global is handled by jss-plugin-global.
  '@global': {
    '.MuiFormControl-root': {
      borderSize: 0,
      borderColor: '#5C2483',
    },
    '.Mui-focused': {
      borderColor: '#5C2483',
    },
    '.MuiInputBase-input': {
      borderColor: '#5C2483',
      '&:focus': {
        borderColor: '#5C2483',
      },
    },
    '.MuiInputBase-input.Mui-disabled': {
      color: '#858585',
    },
    '.MuiOutlinedInput-root': {
      borderSize: 0,
      borderColor: '#5C2483',
      '&:focus': {
        borderColor: '#5C2483',
      },
    },
    '.MuiAccordion-root:before': {
      height: 0,
    },
    '.MuiTypography-root.MuiTypography-body1': {
      fontSize: '14px',
    },
    '.MuiAccordionSummary-root': {
      minHeight: '40px',
      marginTop: '1px',
      marginBottom: '1px',
    },
    '.MuiButtonBase-root.MuiAccordionSummary-root.accordion-sumary.Mui-expanded': {
      background: '#EEE8F2',
    },
    '.MuiPaper-root.MuiDrawer-paper': {
      overflow: 'auto',
      paddingBottom: '70px',
    },
    '.MuiPaper-root.MuiDrawer-paper::-webkit-scrollbar': {
      width: '0px',
    },
    '.MuiChip-deleteIcon': {
      color: '#52197F',
    },
    '.makeStyles-root-1': {
      display: isMobile && 'initial',
    },
    '.MuiAppBar-colorPrimary-106': {
      background: '#fff',
    },
    '.MuiAccordionDetails-root': {
      padding: '0px',
    },
    '.MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded': {
      minHeight: '40px',
      height: '40px',
      color: 'rgb(82, 25, 127)',
    },
    '.MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
    '.MuiAccordionSummary-content.Mui-expanded': {
      margin: '10px 0',
    },
    '.MuiAccordionSummary-expandIcon.Mui-expanded': {
      position: 'relative',
      bottom: '2px',
    },
    '.MuiAccordionSummary-expandIcon': {
      position: 'relative',
      bottom: '2px',
    },
    '.MuiSwitch-colorSecondary.Mui-checked': {
      color: '#52197F',
    },
    '.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
      background: '#CBBAD9',
    },
    '.MuiTableCell-root': {
      padding: '10px 16px',
    },
  },
})(() => null);

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ModalProcessBlockProvider>
          <SuccessModalContextProvider>
            <NiceModal.Provider>
              <SnackbarProvider maxSnack={3}>
                <SnackbarProviderDeprecated>
                  <GlobalCss />
                  <ModalProcessAbandonment />
                    <Routes />
                  <CustomSnackbarDeprecated />
                </SnackbarProviderDeprecated>
              </SnackbarProvider>
            </NiceModal.Provider>
            <ModalStandardSuccess />
          </SuccessModalContextProvider>
        </ModalProcessBlockProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
