import axios from 'axios';

const URL_API = process.env.REACT_APP_API_HOST_URL;

export const api = axios.create({
  baseURL: URL_API,
});

api.interceptors.request.use(
  config => {
    const token = sessionStorage.getItem('@ContaSoma-Token');
    const companyCode = sessionStorage.getItem('currentCompanyCode') ?? '';
    
    if (token) {
      config.headers.Authorization = token;
    }

    if (companyCode) config.headers.companyCodeId = companyCode;
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

api.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    const originalRequest = error.config;
    if (error?.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      return api
        .post('/auth/refreshToken', {
          Authorization: sessionStorage.getItem('@ContaSoma-Token'),
          cpf: sessionStorage.getItem('cpf'),
        })
        .then(res => {
          sessionStorage.setItem('@ContaSoma-Token', res.data.idToken);
          axios.defaults.headers.common.Authorization = `Bearer ${sessionStorage.getItem(
            '@ContaSoma-Token',
          )}`;
          return api(originalRequest);
        });
    }
    return Promise.reject(error);
  },
);
