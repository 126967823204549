import React, { useCallback } from 'react';
import { Fab, Typography } from '@material-ui/core';
import { AddRounded } from '@material-ui/icons';

import { Header } from '../../components/Header';
import Buttons from '../../components/Buttons';
import PixKeyItem from './components/PixKeyItem';
import PixKeysEmptyState from './components/EmptyState';
import PixKeyListSkeleton from './components/ListSkeleton';
import PixKeysModals from './components/Modals';

import { CLAIM_LABEL } from './constants';
import { isMobile, isTablet } from '../../utils/breakpoints';
import { PixProvider, usePix } from './hooks/usePix';
import { PortabilityProvider } from './hooks/usePortability';

import { useStyles } from './styles';
import DrawerMenu from './components/DrawerMenu';
import DrawerContent from './components/DrawerContent';

function PixKeys() {
  const styles = useStyles();

  const {
    data: pixData,
    pixSelected,
    isLoading,
    drawerIsOpen,
    isFetching,
    isDisabled,
    onDeleteKey,
    goToNewKeyPage,
    handleOpenDetailsPortability,
    disableNewKeyPageButton,
  } = usePix();
  const renderButton = useCallback(() => {
    return isMobile || isTablet ? (
      <Fab
        color="primary"
        aria-label="Nova chave Pix"
        className={styles.fab}
        onClick={goToNewKeyPage}
        disabled={isDisabled}
      >
        <AddRounded />
      </Fab>
    ) : (
      <Buttons.PrimaryButton
        className={styles.button}
        color="primary"
        title="Nova chave Pix"
        onClick={goToNewKeyPage}
        icon={<AddRounded />}
        disabled={isDisabled}
      />
    );
  }, [isMobile, isDisabled, isTablet]);

  const renderKeysSubtitle =
    pixData?.length > 0 ? (
      <>
        <strong>{pixData?.length}</strong> de 20 chaves cadastradas
      </>
    ) : null;

  const renderKeyList = () => {
    return !pixData || pixData?.length === 0 ? (
      <PixKeysEmptyState goToNewKeyPage={goToNewKeyPage} />
    ) : (
      <ol className={styles.list}>
        {pixData?.map(pix => (
          <PixKeyItem pixItem={pix} key={pix?.key} onDelete={onDeleteKey} />
        ))}
      </ol>
    );
  };

  const portabilidadeLabel = CLAIM_LABEL[pixSelected?.claim?.claimType]

  return (
    <>
      <Header title="Minhas chaves Pix" buttons={renderButton} />
      <Typography variant="h2" className={styles.subtitle}>
        {renderKeysSubtitle}
      </Typography>

      {isFetching ? <PixKeyListSkeleton /> : renderKeyList()}

      <PixKeysModals />

      <DrawerMenu
        title={`Detalhes da ${portabilidadeLabel}`}
        isOpen={drawerIsOpen}
        handleClose={()=>handleOpenDetailsPortability()}
      >
        <DrawerContent/>
      </DrawerMenu>
    </>
  );
}

const PixKeysScreen = ()=>(
    <PixProvider>
      <PortabilityProvider>
        <PixKeys />
      </PortabilityProvider>
    </PixProvider>
)

export default PixKeysScreen