import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import Form from '../../../../../../../../../components/Form';
import Buttons from '../../../../../../../../../components/Buttons';
import formatCPF from '../../../../../../../../../utils/formatCPF';

import {
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';

import { Alert, Autocomplete } from '@material-ui/lab';

import { useInsertBankData } from './Hooks/useInsertBankData';

import { TemporaryDrawer } from '../../../../../../../../../components/DrawerV2';

export default function InsertBankDataDrawer() {
  const {
    bankOptions,
    insertBankDataDrawer,
    insertBankDataForm,
    employeeName,
    employeeCpf,
    employeeBankData,
    paymentMethodOptions,
    pixOptions,
    watchedValues,
    getPixMasks,
    infoDict,
  } = useInsertBankData();

  const transferOption = watchedValues.paymentMethod;
  const shouldDisableBankDetailsInputs = transferOption === 'pix';

  const shouldShowBankDetailsInputs =
    !insertBankDataForm.errors.pixKey &&
    !!insertBankDataForm.shouldConfirmBankData;

  const handleSubmitAction = () => {
    const actions = {
      pix: insertBankDataForm.getBankInfo,
      pixViaBankDetails: insertBankDataForm.handleSubmit,
    };
    actions[transferOption]();
  };

  const shouldDisableInputSubmit = () => {
    const isPix = watchedValues.paymentMethod === 'pix';
    const isPixInvalid = !watchedValues.pixKey || !watchedValues.pixType;

    return isPix
      ? insertBankDataForm.errors.pixKey ||
          insertBankDataForm.errors.pixType ||
          isPixInvalid
      : !insertBankDataForm.canSubmit || insertBankDataForm.isLoading;
  };

  const getTextSubmitButton = () => {
    if (
      insertBankDataForm.shouldConfirmBankData ||
      transferOption === 'pixViaBankDetails'
    ) {
      return 'Confirmar dados bancários';
    }

    return 'Prosseguir';
  };

  const textButtonSubmit = !!insertBankDataForm.shouldConfirmBankData
    ? 'Confirmar dados bancários'
    : 'Prosseguir';

  return (
    <TemporaryDrawer
      title="Inserir conta bancária"
      subtitle="Enviaremos o pagamento desse colaborador para a conta bancária inserida abaixo:"
      drawerWidth={560}
      open={insertBankDataDrawer.isOpen}
      onClose={insertBankDataDrawer.handleClose}
    >
      <Box mb={4} display="flex" flexDirection="column" sx={{ gap: '1.5rem' }}>
        <Box flexDirection="column" sx={{ gap: '0.375rem' }}>
          <Typography variant="subtitle2" style={{ color: '#808080' }}>
            Inserir conta bancária para:
          </Typography>
          <Typography variant="subtitle2">{employeeName}</Typography>
        </Box>

        {insertBankDataForm.dirtyFields && (
          <Alert severity="info">
            {!!transferOption
              ? 'Os dados inseridos deverão ser do colaborador.'
              : 'É necessário preencher todos os campos obrigatórios.'}
          </Alert>
        )}
      </Box>

      <Form.FormBase>
        <Grid item xs={12} sm={12} md={12} style={{ marginBottom: 24 }}>
          <Controller
            name="paymentMethod"
            control={insertBankDataForm.control}
            rules={{ required: true }}
            render={({ field }) => (
              <Form.SelectField
                label="Opção de transferência*"
                options={paymentMethodOptions}
                error={insertBankDataForm.errors?.paymentMethod}
                helperText={insertBankDataForm.errors?.paymentMethod?.message}
                {...field}
              />
            )}
          />
        </Grid>

        {transferOption === 'pix' && (
          <Grid container direction={{ sm: 'column', md: 'row' }} spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <Controller
                name="pixType"
                control={insertBankDataForm.control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Form.SelectField
                    label="Tipo de chave Pix*"
                    options={pixOptions}
                    error={insertBankDataForm.errors?.pixType}
                    helperText={insertBankDataForm.errors?.pixType?.message}
                    {...field}
                    onChange={value => {
                      insertBankDataForm.setValue('pixType', value);

                      if (value === 'cpf') {
                        insertBankDataForm.setValue(
                          'pixKey',
                          formatCPF(employeeCpf),
                        );
                      } else {
                        insertBankDataForm.setValue('pixKey', '');
                      }
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <Controller
                name="pixKey"
                control={insertBankDataForm.control}
                render={({ field, fieldState }) => (
                  <Form.MaskedField
                    label="Chave Pix*"
                    mask={getPixMasks(watchedValues.pixType)}
                    disabled={watchedValues?.pixType === 'cpf'}
                    error={insertBankDataForm.errors?.pixKey}
                    helperText={insertBankDataForm.errors?.pixKey?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>
        )}

        {(transferOption === 'pixViaBankDetails' ||
          !!shouldShowBankDetailsInputs) && (
          <Grid container spacing={3}>
            {infoDict?.owner?.name && (
              <Grid item xs={12} sm={12} md={12}>
                <Form.TextField
                  label="Nome recebedor"
                  disabled={true}
                  value={infoDict?.owner?.name}
                />
              </Grid>
            )}

            {infoDict?.owner?.taxIdNumber && (
              <Grid item xs={12} sm={12} md={12}>
                <Form.MaskedField
                  label="Documento recebedor"
                  disabled={true}
                  mask={
                    infoDict?.owner?.type === 'LEGAL_PERSON'
                      ? '00.000.000/0000-00'
                      : '000.000.000-00'
                  }
                  value={infoDict?.owner?.taxIdNumber}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Controller
                name="bank"
                control={insertBankDataForm.control}
                render={({ field }) => (
                  <Form.AutocompleteField
                    label="Banco*"
                    options={bankOptions.data}
                    getOptionLabel={e => e?.participantName}
                    getOptionValue={e => e?.codIspb}
                    error={insertBankDataForm.errors?.bank}
                    helperText={insertBankDataForm.errors?.bank?.message}
                    disabled={shouldDisableBankDetailsInputs}
                    {...field}
                    value={watchedValues.bank}
                    isLoading={bankOptions.isLoading}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={8} lg={8}>
              <Controller
                name="agency"
                control={insertBankDataForm.control}
                render={({ field }) => (
                  <Form.TextField
                    label="Agência*"
                    type="number"
                    precision={5}
                    error={insertBankDataForm.errors?.agency}
                    helperText={insertBankDataForm.errors?.agency?.message}
                    disabled={shouldDisableBankDetailsInputs}
                    inputProps={{ maxLength: 4 }}
                    onInput={(e) => {
                      e.target.value = e.target.value.slice(0, 4);
                    }}
                    {...field}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Controller
                name="agencyDigit"
                control={insertBankDataForm.control}
                render={({ field }) => (
                  <Form.TextField
                    fullWidth
                    label="Dígito da agência"
                    variant="outlined"
                    size="small"
                    disabled={shouldDisableBankDetailsInputs}
                    error={insertBankDataForm.errors?.agencyDigit}
                    helperText={insertBankDataForm.errors.agencyDigit?.message}
                    inputProps={{ maxLength: 1 }}
                    {...field}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={8} lg={8}>
              <Controller
                name="account"
                control={insertBankDataForm.control}
                render={({ field }) => (
                  <Form.TextField
                    fullWidth
                    label="Número da conta*"
                    type="number"
                    disabled={shouldDisableBankDetailsInputs}
                    error={insertBankDataForm.errors?.account}
                    helperText={insertBankDataForm.errors.account?.message}
                    inputProps={{ maxLength: 11 }}
                    onInput={(e) => {
                      e.target.value = e.target.value.slice(0, 11);
                    }}
                    {...field}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Controller
                name="accountDigit"
                control={insertBankDataForm.control}
                render={({ field }) => (
                  <Form.TextField
                    fullWidth
                    label="Dígito da conta*"
                    disabled={shouldDisableBankDetailsInputs}
                    variant="outlined"
                    size="small"
                    error={insertBankDataForm.errors?.accountDigit}
                    helperText={insertBankDataForm.errors.accountDigit?.message}
                    inputProps={{ maxLength: 1 }}
                    {...field}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Controller
                name="accountType"
                control={insertBankDataForm.control}
                render={({ field }) => (
                  <TextField
                    select
                    fullWidth
                    label="Tipo da conta*"
                    variant="outlined"
                    disabled={shouldDisableBankDetailsInputs}
                    size="small"
                    error={insertBankDataForm.errors?.accountType}
                    helperText={insertBankDataForm.errors.accountType?.message}
                    {...field}
                  >
                    <MenuItem value="CC">Conta corrente</MenuItem>
                    <MenuItem value="CP">Conta poupança</MenuItem>
                  </TextField>
                )}
              />
            </Grid>
          </Grid>
        )}

        <Box
          display="flex"
          justifyContent="flex-end"
          mt={4}
          sx={{ gap: '1rem' }}
        >
          <Button
            variant="text"
            color="primary"
            onClick={insertBankDataDrawer.handleClose}
          >
            Cancelar
          </Button>
          <Buttons.PrimaryButton
            disabled={shouldDisableInputSubmit()}
            color="secondary"
            title={getTextSubmitButton()}
            loading={insertBankDataForm.isLoading}
            variant="contained"
            onClick={handleSubmitAction}
          />
        </Box>
      </Form.FormBase>
    </TemporaryDrawer>
  );
}
